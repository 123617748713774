import { Navigate, useLocation } from "react-router-dom"
import { useAppSelector } from "../../store/hooks"
import { AuthInitalState } from "../../store/slices/auth-slice"

function PrivateRoute({ children }: { children: JSX.Element }) {
  let location = useLocation()
  const authCtx: AuthInitalState = useAppSelector((state) => state.auth)

  if (!authCtx.isLoggedIn) {
    return <Navigate to="/admin" state={{ from: location }} replace />
  }

  return children
}

export default PrivateRoute
