import { FC } from "react"

interface Props {
  className?: string
}

const Container: FC<Props> = (props) => {
  let cNames = props.className ? props.className + " container" : "container"

  return <div className={cNames}>{props.children}</div>
}

export default Container
