import Container from "../../components/Container"
import Icon from "../../components/Icon"

import styles from "./Offer.module.scss"

import ApplicationImage from "../../assets/images/application.png"
import Stores from "../../components/Stores"
import Contact from "../../components/Contact"

const Offer = () => {
  return (
    <>
      <section className={styles.hero__section}>
        <Container className="text-center">
          <h1>
            Cilj <span className="green">Rezervacije</span> je da bude
            univerzalno rješenje i pomoć prilikom rezervisanja termina.
          </h1>

          <h2 className="green">Šta mi nudimo našim korisnicima?</h2>
        </Container>
      </section>

      <Container className={`${styles.arrow} text-center`}>
        <Icon name="arrow-down" />
      </Container>

      <section
        className={`${styles.page__content__benefits} ${styles.page__content__user}`}
      >
        <Container>
          <Icon name="user-avatar" />
          <h2>Kao korisnik imate pogodnosti:</h2>
          <Container className="flex flex--space">
            <ul>
              <li>
                Pregleda svih radnji u aplikaciji, njihovih usluga i cijena,
                ocjena koje su dobili od klijenata, kao i galeriju njihovih
                slika
              </li>
              <li>
                Zakazivanja termina sa odabirom usluga, vremenskom procjenom
                trajanja termina, kao i vašim odabirom radnika
              </li>
              <li>Davanje ocjene nakon zavrsenog termina</li>
              <li>Pregleda prethodnih i aktivnih narudzbi</li>
              <li>Prijavljivanja ukoliko dođe do greške sa narudžbom</li>
              <li>
                Procjene koliko vremena je potrebno od Vaše lokacije, do radnje
                u kojoj je zakazan termin
              </li>
            </ul>

            <div className={styles.image_box}>
              <img src={ApplicationImage} alt="Aplikacija" />
            </div>
          </Container>
        </Container>
      </section>

      <section className={styles.contact__section}>
        <Container className={`${styles.contact__container}`}></Container>
      </section>

      <section className={`${styles.page__content__benefits}`}>
        <Container>
          <Icon name="medal" />
          <h2>Kao vlasnik i uposlenik imate pogodnosti:</h2>
          <ul>
            <li>
              Pregleda i dodavanja Vaših radnji, mogućnost dodavanja informacija
              kao što su broj telefona, mail, lokacija, ali i galerije vaših
              slika
            </li>
            <li>
              Dodavanje svih uposlenika, kao i pojedinačnih usluga te vremena i
              cijena za svakog uposlenika
            </li>
            <li>
              Pregleda sedmičnog kao i mjesečnog kalendara rezervacija Vas, ali
              i Vaših uposlenih
            </li>
            <li>
              Ličnog dodavanja termina, kao i dodavanja odsustva u određenom
              periodu
            </li>
            <li>
              Otkazivanja narudžbe, kao i dodavanja razloga za otkazivanje
            </li>
          </ul>

          <div className={styles.image_box}>
            <img src={ApplicationImage} alt="Aplikacija" />
          </div>
        </Container>
      </section>

      <Contact />

      <section>
        <Container className={styles.page__content}>
          <h2>
            Korisnici mogu besplatnu aplikaciju preuzeti na Apple Store i Google
            Play servisima, a poslovni subjekti Rezervaciju mogu koristiti za
            intuitivnije vođenje djelatnosti i pristup proširenoj bazi korisnika
            usluga i kupaca.
          </h2>
          <div className={styles.page__stores}>
            <Stores />
          </div>
        </Container>
      </section>
    </>
  )
}

export default Offer
