import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Provider } from "react-redux"

import store from "./store"

import Home from "./pages/Home"
import Offer from "./pages/Offer"
import About from "./pages/About"
import Contact from "./pages/Contact/Contact"
import Partners from "./pages/Partners"
import FAQ from "./pages/FAQ"
import Download from "./pages/Download"
import NotFound from "./pages/NotFound"

import PrivateRoute from "./components/PrivateRoute"
import AdminAuth from "./pages/admin/AdminAuth"
import AdminDashboard from "./pages/admin/AdminDashboard"
import AdminSalon from "./pages/admin/AdminSalon"
import Layout from "./components/Layout"
import ScrollToTop from "./components/ScrollToTop"
import Privacy from "./pages/Privacy"
import Terms from "./pages/Terms"

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Layout />}>
              <Route path="" element={<Home />} />
              <Route path="/sta-nudimo" element={<Offer />} />
              <Route path="/o-nama" element={<About />} />
              {/* <Route path="/novosti" element={<News />} /> */}
              <Route path="/kontakt" element={<Contact />} />
              <Route path="/partneri" element={<Partners />} />
              <Route path="/faq" element={<FAQ />} />
              <Route path="/preuzimanje" element={<Download />} />
              <Route path="/politika-privatnosti" element={<Privacy />} />
              <Route path="/uslovi-koristenja" element={<Terms />} />
              <Route path="*" element={<NotFound />} />
            </Route>

            <Route path="/admin">
              <Route path="" element={<AdminAuth />} />
              <Route
                path="dashboard"
                element={
                  <PrivateRoute>
                    <AdminDashboard />
                  </PrivateRoute>
                }
              />

              <Route
                path="salon/:id"
                element={
                  <PrivateRoute>
                    <AdminSalon />
                  </PrivateRoute>
                }
              />
            </Route>
          </Routes>
        </ScrollToTop>
      </Router>
    </Provider>
  )
}

export default App
