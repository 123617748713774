import authSlice from "./slices/auth-slice"
import salonSlice from "./slices/salon-slice"
import usersSlice from "./slices/user-slice"

const reducer = {
  auth: authSlice.reducer,
  salon: salonSlice.reducer,
  users: usersSlice.reducer,
}

export default reducer
