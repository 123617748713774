import { NavLink, Link } from "react-router-dom"
import { useState, useEffect } from "react"

import Container from "../Container"
import { Scroll, NavToggle } from "./HeaderLogic"
import styles from "./Header.module.scss"

function Header() {
  const scroll = Scroll()

  const [displayBanner, setDisplayBanner] = useState(false)

  useEffect(() => {
    let cAccept = localStorage.getItem("cookieAccepted")
    if (!cAccept) {
      setDisplayBanner(true)
    }
  }, [displayBanner])

  const [toggle, toggleNavigation] = NavToggle()

  const headerStyles = scroll
    ? `${styles.header} ${styles.scroll}`
    : `${styles.header}`

  const headerToggle = toggle
    ? `${headerStyles} ${styles.active}`
    : `${headerStyles}`

  return (
    <>
      {displayBanner && (
        <div className={styles.cookieBanner}>
          <p>
            Korištenjem web stranice rezervacija.app Vi dajete saglasnot da
            koristimo kolačiće. Više o kolačićima možete pročitati na{" "}
            <a href="/politika-privatnosti">ovom</a> linku. Ukoliko niste
            saglasni sa korištenjem kolačića molimo Vas da napustite stranicu{" "}
          </p>
          <button
            onClick={() => {
              localStorage.setItem("cookieAccepted", "YES")
              setDisplayBanner(false)
            }}
          >
            Saglasan sam
          </button>
        </div>
      )}
      <header className={headerToggle}>
        <Container className="flex flex--space">
          <Link to="/" className={styles.brand}>
            <img src="/logo.png" alt="Rezervacija Logo" />
            <span>Rezervacija</span>
          </Link>

          <button
            className={styles.hamburger__menu}
            onClick={() => toggleNavigation()}
          >
            <div></div>
            <div></div>
            <div></div>
          </button>

          <nav className={styles.navigation}>
            <ul>
              <li>
                <NavLink to="/sta-nudimo">Sta nudimo?</NavLink>
              </li>
              <li>
                <NavLink to="/o-nama">O nama</NavLink>
              </li>
              {/* <li>
                <NavLink to="/novosti">Novosti</NavLink>
              </li> */}
              <li>
                <NavLink to="/faq">FAQs</NavLink>
              </li>
              <li>
                <NavLink to="/kontakt">Kontakt</NavLink>
              </li>
              <li className={styles.cta}>
                <NavLink to="/partneri">Postani partner</NavLink>
              </li>
            </ul>
          </nav>
        </Container>
        <nav className={styles.mobile__navigation}>
          <ul>
            <li>
              <NavLink to="/sta-nudimo" onClick={() => toggleNavigation()}>
                Sta nudimo?
              </NavLink>
            </li>
            <li>
              <NavLink to="/o-nama" onClick={() => toggleNavigation()}>
                O nama
              </NavLink>
            </li>
            {/* <li>
              <NavLink to="/novosti" onClick={() => toggleNavigation()}>
                Novosti
              </NavLink>
            </li> */}
            <li>
              <NavLink to="/kontakt" onClick={() => toggleNavigation()}>
                Kontakt
              </NavLink>
            </li>
            <li>
              <NavLink to="/faq" onClick={() => toggleNavigation()}>
                FAQs
              </NavLink>
            </li>
            <li className={styles.cta}>
              <Link to="/partneri" onClick={() => toggleNavigation()}>
                Postani partner
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    </>
  )
}

export default Header
