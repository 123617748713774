import axios from "axios"
import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import Button from "../../../components/Button"
import Container from "../../../components/Container"
import authHeader from "../../../helpers/auth-header"
import { useAppDispatch } from "../../../store/hooks"
import { logout } from "../../../store/slices/auth-slice"

interface SalonType {
  SalonId?: number
  Name?: string
  Number?: string
  Mail?: string
  Location?: {
    City?: string
    Country?: string
  }
  Logo?: {
    Url?: string
  }
  Images?: [{ Url?: string }]
  File: { Url?: string }
}

const AdminSalon = () => {
  let { id } = useParams()
  const [salon, setSalon] = useState<SalonType>({
    SalonId: -1,
    Name: "",
    Number: "",
    Mail: "",
    Location: {
      City: "",
      Country: "",
    },
    Logo: {
      Url: "",
    },
    Images: [{}],
    File: {},
  })

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const logoutHandler = async () => {
    await dispatch(logout())
    navigate("/admin")
  }

  const odobriSalon = async (id?: number) => {
    try {
      if (id) {
        await axios.put(
          "https://api.rezervacija.app/salon/acceptsalon?SalonId=" + id,
          {},
          { headers: authHeader() }
        )

        navigate("/admin/dashboard")
      }
    } catch (e) {
      alert(e)
    }
  }

  useEffect(() => {
    let loadFullSalon = async () => {
      try {
        let response = await axios.get(
          "https://api.rezervacija.app/application/gebyidfullorder" + id,
          { headers: authHeader() }
        )

        setSalon(response.data)
      } catch (e) {
        console.log("ERROR" + e)
      }
    }

    loadFullSalon()
  }, [id])

  return (
    <>
      <Container className="flex flex--space">
        <div>
          <h4>Lista neodobrenih salon - Salon #{id}</h4>
        </div>
        <Button text="Logout" clickHandler={logoutHandler} />
      </Container>
      <Container>
        {salon && (
          <div>
            <h3>Naziv: {salon.Name}</h3>
            <p>Mail: {salon.Mail}</p>
            <p>Broj: {salon.Number}</p>
            <p>
              Lokacija: {salon.Location?.City} {salon.Location?.Country}{" "}
            </p>
            <p>
              Logo:
              <br /> <img src={salon.Logo?.Url} className="asd-image" alt="" />
            </p>
            <p>
              Naslovna: <br />
              <img src={salon.File?.Url} className="asd-image" alt="" />
            </p>

            <p>Ostale slike</p>
            <br />
            <ul className="ostale-slike">
              {salon.Images?.map((el) => {
                return (
                  <li key={el.Url}>
                    <img src={el.Url} className="asd-image" alt="" />
                  </li>
                )
              })}
            </ul>

            <Button
              text="Odobri salon"
              clickHandler={(e) => odobriSalon(salon.SalonId)}
            />
          </div>
        )}
      </Container>
    </>
  )
}

export default AdminSalon
