import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import axios from "axios"
const user = JSON.parse(localStorage.getItem("user")!)

export interface AuthInitalState {
  authToken: string | null
  refreshToken: string | null
  isLoggedIn: boolean
}

type AuthData = {
  userName: string
  password: string
}

export const login = createAsyncThunk(
  "auth/login",
  async (data: AuthData, thunkAPI) => {
    try {
      const response = await axios.post(
        "https://api.rezervacija.app/authentication/login/",
        JSON.stringify(data),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      if (response.data.Token) {
        let jwtData = response.data.Token.split(".")[1]
        let decodedeJWTJSON = window.atob(jwtData)
        let decodedeJWTData = JSON.parse(decodedeJWTJSON)

        if (decodedeJWTData.Role === "Admin") {
          localStorage.setItem(
            "user",
            JSON.stringify({
              authToken: response.data.Token,
              refreshToken: response.data.RefreshToken,
            })
          )
        } else {
          return thunkAPI.rejectWithValue(`Unauthorized User`)
        }
      }

      return {
        authToken: response.data.Token,
        refreshToken: response.data.RefreshToken,
      }
    } catch (e) {
      return thunkAPI.rejectWithValue(`API Failed ${e}`)
    }
  }
)

export const logout = createAsyncThunk("auth/logout", async () => {
  localStorage.removeItem("user")
})

const initialState: AuthInitalState = user
  ? {
      authToken: user.authToken,
      refreshToken: user.refreshToken,
      isLoggedIn: true,
    }
  : { authToken: null, refreshToken: null, isLoggedIn: false }

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      login.fulfilled,
      (state, action: PayloadAction<Omit<AuthInitalState, "isLoggedIn">>) => {
        state.isLoggedIn = true
        state.authToken = action.payload.authToken
        state.refreshToken = action.payload.refreshToken
      }
    )

    builder.addCase(login.rejected, (state, _action) => {
      state.isLoggedIn = false
    })

    builder.addCase(logout.fulfilled, (state, _action) => {
      state.authToken = null
      state.refreshToken = null
      state.isLoggedIn = false
    })
  },
})

export const authActions = authSlice.actions

export default authSlice
