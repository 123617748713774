import { Link } from "react-router-dom"
import { FC, MouseEventHandler } from "react"

import styles from "./Button.module.scss"

interface IProps {
  isSubmit?: boolean
  link?: string
  text: string
  className?: string
  clickHandler?: MouseEventHandler<HTMLButtonElement>
}

const Button: FC<IProps> = ({
  className,
  link,
  text,
  isSubmit,
  clickHandler,
}) => {
  let cNames = className ? `${styles.button} ${className}` : `${styles.button}`

  let component: JSX.Element = <></>

  if (isSubmit && !link) {
    component = (
      <button type="submit" className={cNames}>
        {text}
      </button>
    )
  }

  if (link && !isSubmit) {
    component = (
      <Link to={link} className={cNames}>
        {text}
      </Link>
    )
  }

  if (clickHandler) {
    component = (
      <button className={cNames} onClick={clickHandler}>
        {text}
      </button>
    )
  }

  return component
}

export default Button
