import { AxiosRequestHeaders } from "axios"

export default function authHeader(): AxiosRequestHeaders {
  const user = JSON.parse(localStorage.getItem("user")!)
  if (user && user.authToken) {
    return { Authorization: "Bearer " + user.authToken }
  } else {
    return {}
  }
}
