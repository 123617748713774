import { ReactSVG } from "react-svg"
import { FC } from "react"

interface Props {
  name: string
  className?: string
}

const Icon: FC<Props> = (props) => {
  return (
    <ReactSVG
      className={props.className}
      src={`/icons/${props.name}.svg`}
      wrapper="svg"
    />
  )
}

export default Icon
