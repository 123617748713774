import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import authHeader from "../../helpers/auth-header"

export const getSalons = createAsyncThunk(
  "salon/getSalons",
  async (_, thunkAPI) => {
    try {
      let response = await axios.get(
        "https://api.rezervacija.app/salon/paging?IsActive=false",
        { headers: authHeader() }
      )

      return { list: response.data.list }
    } catch (e) {
      return thunkAPI.rejectWithValue("Error getUnApproved while fetching")
    }
  }
)

export interface SalonList {
  SalonId: number
  Name: string
  Mail: string
  Number: string
}

const initialState = {
  list: [],
  status: "",
}

const salonSlice = createSlice({
  name: "salon",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSalons.fulfilled, (state, action) => {
      state.list = action.payload.list
      state.status = "success"
    })

    builder.addCase(getSalons.pending, (state, action) => {
      state.status = "loading"
    })

    builder.addCase(getSalons.rejected, (state, action) => {
      state.list = []
      state.status = "failed"
    })
  },
})

export const salonActions = salonSlice.actions
export default salonSlice
