import axios from "axios"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import Button from "../../../components/Button"
import Container from "../../../components/Container"
import authHeader from "../../../helpers/auth-header"
import { backendUrl } from "../../../helpers/globalVariables"
import styles from "../AdminEmail/AdminEmail.module.scss"

type Inputs = {
  language: string
  userType: string
  notVerifiedNumber: boolean
  headerText: string
  notificationText: string
}

export default function AdminPushNotifications() {
  const { register, handleSubmit, reset } = useForm<Inputs>()
  const [isFormSent, setIsFormSent] = useState(false)

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    try {
      await axios.post(`${backendUrl}application/sendpushnotification`, data, {
        headers: authHeader(),
      })
      reset()
      setIsFormSent(true)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Container className="flex flex--space">
        <div>
          <h4>Slanje push notifikacija korisnicima</h4>
          <h4>Upute:</h4>
          <p>
            <strong>Language</strong> - bice poslan korisnicima koji koriste
            poslani jezik, recimo “bs” samo korisnicima sa “bs” ce biti poslan,
            ako onaj koji salje mail smatra da isti mail moze poslati na
            bs,sr,hr, neka ga posalje 3x sa razlicitim jezikom.
            <br /> <strong>Header Tekst</strong>- header notifikacije <br />
            <strong>NotificationText</strong> - sadrzaj notifikacije <br />
            <strong>UserType</strong> - string je i moze biti: ”Owner”- salje se
            vlasnicima ciji je salon aktivan i koji pripadaju jeziku recimo en
            ”Employee” - salje se svim aktivnim uposlenicima ”OwnerEmployee” -
            vlasnicima i uposlenicima “ALL” - svim korisnicima aplikacije u
            zavisnosti od parametra
            <br />
            <strong>NotVerifiedNumber</strong> - true slati ukoliko zelimo
            poslati mail samo korisnicima koji nisu verifikovali broj, inace
            saljemo false
          </p>
          {isFormSent && (
            <p className={styles.formConfirmation}>
              Push notifikacija uspjesno poslana
            </p>
          )}
        </div>
      </Container>

      <Container className={styles.mailForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label htmlFor="language">Odaberi jezik</label>
            <select
              id="language"
              {...register("language", { required: true })}
              defaultValue={""}
            >
              <option value="" disabled>
                Odaberite jezik
              </option>
              <option value="bs">Bosanski</option>

              <option value="hr">Hrvatski</option>

              <option value="sr">Srpski</option>

              <option value="en">Engleski</option>
            </select>
          </div>

          <div>
            <label htmlFor="userType">Odaberi tip korisnika</label>
            <select
              id="userType"
              {...register("userType", { required: true })}
              defaultValue={""}
            >
              <option value="" disabled>
                Odaberite tip korisnika
              </option>
              <option value="Owner">Owner</option>

              <option value="Employee">Employee</option>

              <option value="OwnerEmployee">OwnerEmployee</option>

              <option value="ALL">Svi</option>
            </select>
          </div>
          <div>
            <label htmlFor="notVerifiedNumber">
              Da li se salje push notfikacija samo neverifikovanim korisnicima?
            </label>
            <input
              id="notVerifiedNumber"
              type="checkbox"
              {...register("notVerifiedNumber")}
            />
          </div>
          <div>
            <label htmlFor="headerText">Naslov notifikacije</label>
            <input
              type="text"
              id="headerText"
              placeholder="Naslov push notifikacija"
              {...register("headerText", { required: true, minLength: 3 })}
            />
          </div>

          <div>
            <label htmlFor="notificationText">Sadrzaj push notifikacije</label>

            <textarea
              id="notificationText"
              cols={30}
              rows={3}
              placeholder="Unesite sadrzaj notifikacije"
              {...register("notificationText", {
                required: true,
                minLength: 10,
              })}
            ></textarea>
          </div>

          <Button isSubmit={true} text="Pošalji" className={styles.btnSend} />
        </form>
      </Container>
    </>
  )
}
