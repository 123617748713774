import { useRef, FormEvent, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../../../components/Button"
import Container from "../../../components/Container"
import InputField from "../../../components/InputField"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { login } from "../../../store/slices/auth-slice"

import styles from "./AdminAuth.module.scss"

const AdminAuth = () => {
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)

  const authCtx = useAppSelector((state) => state.auth)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    if (authCtx.isLoggedIn) {
      navigate("/admin/dashboard")
    }
  }, [authCtx, navigate])

  const onSubmitHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const emailValue = emailRef.current?.value || ""
    const passwordValue = passwordRef.current?.value || ""

    try {
      await dispatch(
        login({ userName: emailValue, password: passwordValue })
      ).unwrap()

      navigate("/admin/dashboard")
    } catch (e) {
      console.log("ERROR" + e)
    }
  }

  return (
    <>
      <div className={styles.auth__wrapper}>
        <Container className={styles.auth__container}>
          <h1>Login</h1>
          <form onSubmit={onSubmitHandler}>
            <InputField
              id="email"
              type="email"
              placeholder="Email"
              label="Email"
              ref={emailRef}
            />
            <InputField
              id="password"
              type="password"
              placeholder="Lozinka"
              label="Lozinka"
              ref={passwordRef}
            />
            <div className="text-center">
              <Button isSubmit={true} text="Login" />
            </div>
          </form>
        </Container>
      </div>
    </>
  )
}

export default AdminAuth
