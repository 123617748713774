import Container from "../../components/Container"

import styles from "./Partners.module.scss"

import ApplicationImage from "../../assets/images/application.png"
import PartnerImage from "../../assets/images/partneri.png"
import Benefit from "../../components/Benefit"
import Contact from "../../components/Contact"

const Partners = () => {
  return (
    <>
      <section className={styles.hero__section}>
        <Container className="flex">
          <div className={styles.hero__content}>
            <h1>
              Želite unaprijediti svoje
              <span className="green"> poslovanje? </span>
              Ostvariti bolju i lakšu komunikaciju sa
              <span className="green"> klijentima? </span>Izbjeći gubitke u
              poslovanju zbog otkazivanja rezervisanih termina?
            </h1>
            <h1>
              Postanite partner <span className="green">Rezervacije</span>!
            </h1>
          </div>

          <div className={styles.hero__image}>
            <img src={PartnerImage} alt="Aplikacija" />
          </div>
        </Container>
      </section>

      <section className={styles.page__content}>
        <Container className="flex flex--space">
          <h2>
            Rezervacija je jedinstveno rješenje za rezervaciju termina i usluga
            na bh. tržištu.
          </h2>
          <div className={styles.page__image}>
            <img src={ApplicationImage} alt="Aplikacija" />
          </div>
        </Container>
      </section>

      <section className={styles.benefits}>
        <Container>
          <h2>
            Koje prednosti ostvaruju partneri{" "}
            <span className="green">Rezervacije?</span>
          </h2>

          <div className={styles.benefits__rows}>
            <Benefit
              numPoint="01"
              title="Jednostavan sistem"
              body="Rezervacije je prilagođena uposlenicima i poslodavcima. Jednostavno odobrite ili otkažite rezervacije, kontaktirajte klijente, dodajte nove usluge i pregledajte historijat rezervisanja"
            />

            <Benefit
              numPoint="02"
              title="Zaštita od zloupotrebe"
              body="Posvećeni smo jačanju transparentnog poslovanja, ali i zaštiti partnera. Sistem Rezervacije automatski onemogućava rezervisanje onima koji zloupotrebljavaju sistem"
              reverse={true}
            />

            <Benefit
              numPoint="03"
              title="Bez skrivenih troškova"
              body="Naši partneri plaćaju fiksnu mjesečnu cijenu članarine, a partnerski paketi su prilagođeni djelatnosima različitih obima i poslovnih ciljeva"
            />

            <Benefit
              numPoint="04"
              title="Vaš posao, naša reklama"
              body="Naši kreativni i PR timovi stoje vam na raspolaganju. Vršit ćemo ciljanu promociju vaših usluga, onima koji ih trebaju"
              reverse={true}
            />

            <Benefit
              numPoint="05"
              title="Prioritetna korisnička podrška"
              body="Rješenje eventualnih problema je na samo jedan poziv ili e-mail od vas - tim podrške Rezervacije je tu za sve naše partnere!
              "
            />
          </div>
        </Container>
      </section>

      <Contact />
    </>
  )
}

export default Partners
