import { Link } from "react-router-dom"
import ContactForm from "../../components/Contact"
import Container from "../../components/Container"

import styles from "./Contact.module.scss"
const Contact = () => {
  return (
    <>
      <section className={styles.hero__section}>
        <Container className="flex">
          <div className={styles.hero__content}>
            <h1>
              Trebate još informacija ili želite započeti proces prijave?
              Popunite formular i mi ćemo vam se javiti.
            </h1>
            <h2>
              Ukoliko ne možete pronaći odgovor na vaše pitanje u našoj{" "}
              <Link to="/faq">
                <span className="green">FAQ</span>
              </Link>{" "}
              sekciji, upišite poruku i ispravnu e-mail adresu, te očekujte naš
              odgovor unutar 24 sata.
            </h2>
          </div>
        </Container>
      </section>
      <ContactForm displayContent={false} />
    </>
  )
}

export default Contact
