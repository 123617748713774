import Container from "../../components/Container"
import Icon from "../../components/Icon"

import styles from "./Download.module.scss"

import Stores from "../../components/Stores"
import { useEffect } from "react"

const Download = () => {
  useEffect(() => {
    if (
      navigator.userAgent.indexOf("iPhone") > -1 ||
      navigator.userAgent.indexOf("iPod") > -1 ||
      navigator.userAgent.indexOf("iPad") > -1
    ) {
      window.location.href =
        "https://apps.apple.com/hr/app/rezervacija/id1616344992?l=hr"
    } else if (/Android/.test(navigator.userAgent)) {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.rezervacija.rezervacija_app&hl=en&gl=US"
    }
  }, [])

  return (
    <section className={styles.hero__section}>
      <Container className="text-center">
        <h1>
          Preuzmite <span className="green">Rezervaciju</span>!
        </h1>

        <div>
          <Icon name="qr-code" />
        </div>

        <Stores />
      </Container>
    </section>
  )
}

export default Download
