import Container from "../../components/Container"

import styles from "./Privacy.module.scss"

const Privacy = () => {
  return (
    <>
      <section className={styles.hero__section}>
        <Container className="text-center">
          <h1>Politika privatnosti</h1>
          <p>Ažurirano: 12.11.2022</p>
        </Container>
      </section>

      <section className={`${styles.page__content}`}>
        <Container>
          <h2>Uvod</h2>

          <p>
            Dio “Politika privatnosti” Vas detaljno upoznaje sa načinom na koji
            se Vaši podaci skupljaju i koriste na platformi{" "}
            <strong>Rezervacija</strong>. Podaci koje korisnik unese na
            platformi (ime, prezime, email adresa, itd.) kao i kolačići (eng.
            “Cookies”) koje korisnik platforme prihvati se koriste isključivo za
            rezervisanje termina usluga i u svrhu što boljeg rada platforme.
            Prihvatanjem kolačića koji se koriste na platformi Rezervacija,
            poboljšava se korisničko iskustvo i funkcionalnost same platforme.
            Kolačići su minijaturne datoteke koje se pohranjuju na računar ili
            mobilni uređaj kada posjetimo neku web stranicu. Kolačići
            dozvoljavaju određenoj web stranici da zapamti aktivnosti ili
            postavke posjetitelja iz prethodnih posjeta, kako bi ih pri
            sljedećoj posjeti mogla automatski primijeniti. Prilikom pristupanja
            web stranici, Rezervacijini kolačići identificiraju parametre
            pretraživača, ali ne i samih korisnika. Svrha kolačića je
            dobronamjerna i ne mogu nositi viruse. Rezervacija koristi tzv.
            Kolačiće (cookies) kao Vaš anonimni individualni identifikator, na
            način da ga web lokacija šalje Vašem pregledniku i pohranjuje na
            Vaše računalo.
          </p>
          <p>
            Podatke unesene preko mobilne aplikacije{" "}
            <strong>Rezervacija</strong> ili sajta <strong>Rezervacija</strong>{" "}
            imaju na raspolaganju dvije strane, i to:
          </p>

          <ul>
            <li>
              Servis Rezervacija - koji podatke koristi u svrhu rezervisanja
              termina za ponuđene usluge i
            </li>
            <li>Partner koji pruža usluge.</li>
          </ul>

          <p>
            Privatnost korisnika platforme <strong>Rezervacija</strong> i
            privatnost naših partnera koji pružaju usluge je uvijek
            zagarantovana.{" "}
          </p>

          <h2>Podaci koje sakupljamo</h2>

          <p>
            Ukoliko posjetite platformu <strong>Rezervacija</strong>, i želite
            koristiti usluge koje naša platforma pruža putem mobilne aplikacije,
            od vas će se tražiti da unesete lične informacije kao što su ime,
            prezime, spol, datum rođenja, telefon i email adresu. Uzimamo i
            pohranjujemo bilo koje podatke s Vašim znanjem koje nam šaljete kada
            napravite račun, objavite sadržaj, izvršite narudžbu ili popunite
            online formu putem mobilne aplikacije. Ako bude potrebno, ove
            informacije mogu sadržavati sljedeće:
          </p>

          <ul>
            <li>
              <strong>Kontakt informacije: </strong> Uključuju Vaše ime,
              prezime, email adresu, broj telefona i bilo kakav sadržaj od
              značaja između Vas i nas. Ukolite ste odlučili da postanete naš
              partner, dodatne potrebne informacije su ime kompanije te vrsta
              djelatnosti kojom se bavite.
            </li>
            <li>
              <strong>Autentifikacijski podaci: </strong>Uključuju Vaše
              korisničko ime i lozinku kada se prijavite na aplikaciju, te Vaš
              device ID. Ukoliko se prijavite putem treće strane (providera),
              uzimamo i Vaš user ID povezan sa odabranim providerom.
            </li>

            <li>
              <strong>Podaci o profilu: </strong>Informacije koje dodate na Vaš
              profil koji kreirate putem našeg servisa ili mobilne aplikacije,
              uključujući sve slike koje objavite i informacije o Vama. Ako se
              prijavite putem treće strane (nekog od login providera), Vašu
              profilnu sliku ćemo uzeti preko odabranog providera. Ukolite ste
              odlučili da postanete naš partner, dodatne informacije uključuju
              sve slike o Vašoj radnji, te informacije koje unesete o Vašoj
              radnji (kao što su Vaše usluge koje nudite, te informacije o
              uslugama).
            </li>
            <li>
              <strong>Podaci o korištenju: </strong> Informacije vezane za Vašu
              posjetu našem servisu i mobilnoj aplikaciji uključuju IP adresu,
              lokaciju, vrijeme i datum, identifikacioni token Vašeg mobilnog
              uredjaja, vrsta preglednika koji koristite te informacije o
              uređaju koji koristite. Takodjer se prikupljaju informacije o
              Vašoj aktivnosti kao što su pregledi, komentari, odgovori i
              pitanja koje ostavite na mobilnoj aplikaciji.
            </li>
          </ul>

          <p>
            Od izuzetne je važnosti da svi podaci koje podijelite sa nama
            prilikom registracije i daljeg korištenja platforme Rezervacija budu
            ispravni i tačni. Ukoliko bi u međuvremenu mijenjali bitne podatke
            za komunikaciju između Vas i servisa <strong>Rezervacija</strong>{" "}
            kao što su email adresa i broj telefona, u obavezi ste da nas o toj
            izmjeni blagovremeni obavijestite, te ukoliko ne postupite na
            prednje navedeni način Rezervacija ne odgovara za štetu uzrokovanu
            propuštanjem ispunjenja Vaših obaveza.
          </p>

          <p>
            U slučaju da želite da se registrujete i izvršite rezervaciju u
            nečije ime, jako je bitno da imate pristanak te treće strane za
            korištenje njihovih podataka, s obzirom na prikupljanje svih tipova
            informacija navedenih u ovom dokumentu. Slijedom prednje navedenog,
            Rezervacija ne odgovara za štetu prouzrokovanu trećim osobama.
          </p>

          <h2>Kako prikupljamo Vaše podatke</h2>

          <p>
            Kada koristite mobilnu aplikaciju <strong>Rezervacija</strong>, naši
            serveri automatski bilježe podatke koje šalje Vaš uredjaj. Ovi
            podaci mogu uključivati informacije kao što su Vaša IP adresa i
            lokacija, device ID, ime i verzija uredjaja, operativni sistem i
            njegova verzija, jezička preferencija podešena na Vašem uredjaju,
            informacije koje pretražujete u mobilnoj aplikaciji, vrijeme i datum
            pristupa i ostale statističke podatke. Svi prikupljeni podaci se
            šalju na naš server https://api.rezervacija.app.
          </p>

          <h2>Na koji način koristimo Vaše podatke?</h2>

          <p>
            Rezervacija koristi Vašu lokaciju kako bi Vam omogućili prikaz
            radnji koje su najbliže udaljenje od Vaše lokacije čak i kada je
            aplikacija zatvorena i nije u korištenju. Ukoliko odbijete pristup
            Vašoj lokaciji, radnje neće biti prikazane redoslijedom udaljenosti
            od Vaše lokacije, ali ćete moći normalno koristiti mobilnu
            aplikaciju.
          </p>

          <p>
            Koristimo i spremamo identifikacioni token Vašeg mobilnog uredjaja
            kako bi Vam mogli slati push notifikacije putem mobilne aplikacije.
          </p>

          <p>
            Vaše lične podatke koristimo s ciljem pomaganja u pronalasku željene
            usluge, u svrhu plasiranja ciljanog sadržaja za Vas kao i u svrhe
            istraživanja, slanja poruka marketinške, promotivne ili informativne
            prirode.
          </p>

          <p>
            Pored toga, Vaši lični podaci koje ostavite na platformi se koriste
            i za sljedeće:
          </p>

          <ul>
            <li>
              <strong>
                Za kreiranje ugovora ili drugih izmjena vezanih za ugovor.
              </strong>{" "}
              Ovo se smatra bitnim prilikom traženja usluge preko{" "}
              <strong>Rezervacije</strong> i uključuje:
              <ul>
                <li>
                  Slanje neophodnih informacija vezanih za rezervisani termin
                </li>
                <li>
                  Poboljšanje načina na koji se vrše Vaše rezervacije i
                  eventualna plaćanja
                </li>
                <li>Odgovori na Vaše upite</li>
                <li>
                  Kvalitetno vođenje naloga koji ste kreirali na platformi
                </li>
              </ul>
            </li>

            <li>
              <strong>
                Za sve aktivnosti koje je potrebno sprovesti kako bi vodili naše
                poslove kao i za naše legitimne interese
              </strong>
              , uključujući:
              <ul>
                <li>
                  Održavanje platforme i sprovođenje sigurnosnih aktivnosti kako
                  bi platforma bila bezbjedna od zloupotrebe
                </li>
                <li>
                  Provjeru usaglašenosti sa postavljenim uslovima korištenja
                  platforme, te odgovaranje na sve pravne zahtjeve
                </li>
                <li>
                  Obavještavanje o ponudama ili uslugama za koje mislimo da su
                  relevantne za Vas
                </li>

                <li>
                  Personaliziranje sadržaja i proizovda prikazanih na Platformi
                  koje se radi na osnovu podataka koji su prikupljeni
                  (pogledajte dio “Kolačići” za više informacija)
                </li>
                <li>
                  Poboljšanje, unaprijeđenje i zaštita platforme s ciljem da
                  korisnicima pružimo što bolje korisničko iskustvo te da
                  unaprijedimo sam rad platforme te poboljšamo kvalitetu usluga
                  koje naši partneri nude na osnovu prikupljenih podataka
                  (pogledajte dio “Kolačići” za više informacija) i dr.
                </li>
              </ul>
            </li>

            <li>
              Dajete nam saglasnost za sljedeće:
              <ul>
                <li>
                  Obaviještavanje o specijalnim ponudama, uslugama koje bi Vas
                  mogle zanimati putem emaila, poruka, notifikacija i sl.
                </li>
                <li>
                  Slanje informacija o nagradnim igrama, bonusima, anketama za
                  poboljšanje platformi ili usluga koje pružaju naši partneri
                  putem emaila, poruka, notifikacija i sl.
                </li>
                <li>
                  Proslijeđivanje Vaših podataka našim partnerima i drugim
                  trećim stranama u svrhu promocije njihovih proizvoda i usluga
                  te pronalaska proizvoda/usluga koji bi Vas eventualno zanimali
                </li>
                <li>
                  Postavljanje kolačića i korištenje srodnih tehnologija u svrhu
                  poboljšanja korisničkog iskustva (u skladu sa dijelom ovog
                  dokumenta koji se odnosi na “Kolačiće”)
                </li>
                <li>
                  Korištenje Vaših podataka u svim drugim prilikama kada Vas
                  pitamo za saglasnost ili za zakonski propisane potrebe
                </li>
              </ul>
            </li>
          </ul>

          <h2>Zaštita Vaših ličnih podataka</h2>

          <p>
            Cjelokupan rad servisa <strong>Rezervacija</strong> je baziran na
            sigurnoj i jednostavnoj komunikaciji u svrhu lakšeg dolaska do
            željenih usluga. Međutim, iako je platforma Rezervacija izrađena
            tako da u svakom momentu štiti Vaše lične podatke korištenjem
            rigoroznih procedura i sigurnosnih protokola, ne možemo garantovati
            da ne može doći do zloupotrebe Vaših podataka, uzevši u obzir da
            prenos bilo kakvih informacija putem interneta nije potpuno
            bezbijedan.{" "}
          </p>

          <h2>Povlačenje saglasnosti i odbijanje direktnog marketinga</h2>

          <p>
            U svakom momentu možete povući bilo koju od prethodno datih
            saglasnosti ukoliko smatrate da dijeljenje podataka na platformi{" "}
            <strong>Rezervacija</strong> nije ono što ste željeli.{" "}
          </p>

          <p>
            Pored toga, uzevši u obzir naše zajedničke interese, ponekada smo u
            mogućnosti da Vam proslijedimo direktni marketing bez Vašeg
            pristanka. Ukoliko ne želite primati ovakve poruke, uvijek imate
            mogućnost da odustanete tj. da odbijete ovaj tip oglašavanja. Npr.
            ukoliko se radi o email komunikaciji, odustati možete jednostavno
            prateći uputstvo navedeno u poruci. Uobičajeno se link za
            isključivanje sa liste primaoca ovakvih poruka nalazi na samom dnu
            poruke u vidu riječi “Odjava”. Dopustite da prođe 48 sati kako bi u
            potpunosti bili uklonjeni sa liste primaoca ili u suprotnom nas
            kontaktirajte na email adresu{" "}
            <a href="mailto:podrska@rezervacija.app">podrska@rezervacija.app</a>
            .
          </p>

          <p>
            Ukoliko platformi pristupate sa mobilnog uređaja i ne želite da
            primate određene notifikacije (“push” obavještenja), iste možete
            isključiti u postavkama Vašeg operativnog sistema tako što ćete
            ukloniti dozvolu za ovaj tip obavještavanja za aplikaciju.{" "}
          </p>

          <h2>Dijeljenje ličnih podataka sa trećim licima</h2>

          <p>
            Iako raspolažemo sa ličnim podacima koje ste podijelili sa nama na
            platformi Rezervacija, nikada nećemo iznevjeriti Vaše povjerenje i
            zloupotrijebiti Vaše lične podatke ni pod kojim okolnostima.{" "}
          </p>

          <p>
            Međutim, u nekim slučajevima Vaše lične ćemo podijeliti sa odabranim
            trećim licima, a to uključuje:
          </p>

          <ul>
            <li>
              Partnerske kompanije koje se nalaze unutar naše kompanijske grupe
              u svrhe identifikovane u ovom dokumentu.
            </li>
            <li>
              Ukoliko ste napravili rezervaciju na platformi, Vaši podaci će se
              proslijediti trećoj strani tj. našem partneru kod kojeg ste
              rezervisali termin za uslugu.
            </li>
            <li>
              Treća lica - pojedini dobavljači usluga koji na neki svojstven
              način doprinose radu platforme <strong>Rezervacija</strong>, bilo
              da su zaduženi za pridobijanje kandidata, pružanje usluga kao što
              su obrada plaćanja, hosting internet lokacija, analiza društvenih
              profila, marketing, anketiranje, i sl.{" "}
            </li>
            <li>
              Partnerski brendovi sa kojima smo ostvarili saradnju promovisanjem
              njihovih proizvoda ili usluga na platformi.
            </li>
            <li>
              Mediji i partneri koji pomažu pri oglašavanju usluga ponuđenih na
              platformi.
            </li>
            <li>
              U slučaju da našu imovinu prenosimo, prodajemo, ili kupujemo, ili
              dođemo u stanje nelikvidnosti, stečaja ili bankrota, postoji
              mogućnost da ćemo, ukoliko za to bude potrebe, Vaše lične podatke
              morati podijeliti sa potencijalnim prodavcem ili kupcem takvog
              posla ili imovine.
            </li>
            <li>
              Vlasti i druge treće strane kojima je određeno da djeluju zakonu
              tj. po sudskim naredama. Dodatno, imamo potpuno pravo da otkrijemo
              Vaše lične podatke ukoliko se ispostavi da ste djelovanjem na
              platformi Rezervacija nekome nanijeli povrede, štetu ili da ste
              ugrozili naša prava ili imovinu.{" "}
            </li>
            <li>
              Vlasti i druge treće strane kojima je određeno da djeluju zakonu
              tj. po sudskim naredama. Dodatno, imamo potpuno pravo da otkrijemo
              Vaše lične podatke ukoliko se ispostavi da ste djelovanjem na
              platformi Rezervacija nekome nanijeli povrede, štetu ili da ste
              ugrozili naša prava ili imovinu.{" "}
            </li>
            <li>
              Prednje navedeno postupanje je u potpunosti zakonito i
              ustanovljeno u cilju zaštite ličnih podataka u skladu sa
              pozitivnim propisima iz ove oblasti.
            </li>
          </ul>

          <i>Napomena:</i>
          <p>
            U slučaju da ste Vaše lične podatke objavili na javno dostupim
            mjestima naše platforme, Rezervacija ne snosi odgovornost ukoliko
            isti dođu u ruke trećih lica, niti odgovara za štetu po bilo kojem
            osnovu.
          </p>

          <h2>Prava koja imate u vezi sa Vašim ličnim podacima</h2>

          <p>
            U svakom momentu od nas možete zahtijevati da uradimo sljedeće u
            vezi sa Vašom ličnim podacima:
          </p>

          <ul>
            <li>
              Da napravimo ispravke Vaših ličnih podataka, da ograničimo njihovu
              upotrebu ili da ih potpuno uklonimo
            </li>
            <li>Da Vam napravimo kopiju Vaših ličnih podataka</li>
            <li>Da tražite da Vaše podatke prenesemo u drugu organizaciju</li>
          </ul>

          <p>
            Vaše podatke možete izmjeniti u postavkama Vašeg naloga a Vaša prava
            vezana za rukovanje Vašim ličnim podacima su u određenim okolnostima
            ograničena.{" "}
          </p>

          <h2>Druge web lokacije povezane sa platformom</h2>

          <p>
            Ne snosimo odgovornost za sadržaj ili postupke na drugim web
            lokacijama do kojih se može doći sa platforme{" "}
            <strong>Rezervacija</strong>. Treba imati na umu da naša politika
            privatnosti vrijedi isključivo na platformi{" "}
            <strong>Rezervacija</strong> i ne prenosi se na druge web lokacija
            do kojih ste mogli doći preko platforme. Uvijek pročitajte pravila
            koja se odnose na drugu web lokaciju prije nego što nastavite.
          </p>

          <h2>Promjene vezane za našu politiku privatnosti</h2>

          <p>
            Sve eventualne promjene u našoj politici privatnosti će biti
            objavljene na platformi <strong>Rezervacija</strong> kako bi imali
            uvid u način na koji prikupljamo Vaše podatke i pod kojim
            okolnostima ih možemo otkriti, a sve u cilju transparentnog
            poslovanja.
          </p>

          <h2>Gdje čuvamo Vaše podatke</h2>

          <p>
            Svi lični podaci prikupljeni na platformi se mogu čuvati na sigurnoj
            lokaciji izvan teritorija Bosne i Hercegovine, uključujući i lične
            podatke koje podijelimo sa trećim stranama u svrhu lakšeg rada i
            poslovanja servisa <strong>Rezervacija</strong>. Svako prebacivanje
            podataka na druge lokacije se vrši u skladu sa pozitivnim propisima
            države u kojoj se podaci nalaze.
          </p>

          <h2>Zadržavanje podataka</h2>

          <p>
            Vaši lični podaci će biti zadržani onoliko koliko je potrebno da bi
            izvršili aktivnosti navedene u ovom dokumentu i u okviru vremenskog
            perioda koji je dozvoljen zakonom.
          </p>

          <h2>Naša politika o kolačićima i sličnim tehnologijama </h2>

          <p>
            Kao što to rade mnoge komercijalne aplikacije i web lokacije, i naš
            servis koristi sve standardne tehnologije, uključujući kolačiće
            (eng. “Cookies”), platforme za oglašavanje (npr. Google AdWords),
            razne alate za marketing i analitiku (npr. Facebook Retargeting
            Pixel ili Google Analytics), i sl. za potrebe navedene u nastavku
            ovog dokumenta.{" "}
          </p>

          <p>
            Ukoliko niste upoznati sa načinom na koji treće strane mogu
            koristiti podatke iz kolačića postavljene na platformi, molimo Vas
            da obratite pažnju na dio koji se odnosi na pravila o privatnosti
            trećih strana. Ukoliko želite da treće strane ne mogu doći do tih
            podataka, molimo Vas da pogledate zadnji dio ovog dokumenta pod
            nazivom “Izmjena postavki kolačića” za više informacija.
          </p>

          <h2>Zašto koristimo kolačiće?</h2>

          <ul>
            <li>
              <strong>
                Zbog neophodnih transakcijskih potreba <br />
              </strong>
              Kolačići se između ostalog suštinski koriste i kako bi platforma{" "}
              <strong>Rezervacija</strong> pravilno funkcionisala tj. kako bi
              Vaš rad na platformi bio omogućen, te za korištenje svih dostupnih
              funkcija i alata, sprječavanje zloupotrebe platforme, kako bi
              zapamtili koje ste usluge odabrali. Ovakva vrsta kolačića se ne
              može isključiti jer platforma u suprotnom ne bi funkcionisala.
            </li>
            <li>
              <strong>
                Zbog funkcionalnosti <br />
              </strong>
              Korištenje kolačića nam omogućava da znatno poboljšamo korisničko
              iskustvo tako što će Vaši odabiti na platformi biti zapamćeni i
              primjenjeni prilikom narednih posjeta, ukoliko prihvatite
              kolačiće.
            </li>
            <li>
              <strong>
                Zbog performansi <br />
              </strong>
              Sa stanovišta kreatora platforme, ovaj tip kolačića im omogućava
              da saznaju koliko korisnika posjećuje platformu, koji dijelovi
              platforme su najposjećeniji, koliko dugo se korisnici zadržavaju
              na određenim dijelovima platforme, i sl. Svi ovi parameteri mogu
              doprinijeti daljnjem razvoju platforme i poboljšanju korisničkog
              iskustva. U ove svrhe se koriste i već pomenute tehnologije trećeg
              lice kao što su Google Analytics, Firebasee, Fullstory, i sl.
              Efikasnost marketinga i aktivnosti koje se sprovode kako bi se
              povećao promet na platformi <strong>Rezervacija</strong> se može
              izmjeriti upotrebom ovih alata. Koriste se i kolačići treće strane
              kao što su Google AdWords, DoubleClick, Bing, te alate društvenih
              medija (Facebook, Instagram, Viber, Pinterest i Snapchat).
            </li>

            <li>
              <strong>
                Zbog oglašavanja i ciljanog oglašavanja <br />
              </strong>
              Kolačići se također koriste i kako bi omogućili prikazivanje
              oglasa relevantnih za Vas i izvan platforme{" "}
              <strong>Rezervacija</strong>. Kolačići pomažu pri prikupljanju
              podataka o Vašim interesovanjima, navikama kako bi vidjeli
              personalizovane reklame i na drugim web lokacijama. Postoje i
              alati koji mjere efikasnost prikazivanja ovakvih reklama, a
              uključuju, ali nisu ograničeni na Google Analytics, Facebook
              Analytics, Bing, i dr. ciljano oglašavanje se također koristi i na
              svim popularnim društvenim mrežama.
            </li>
          </ul>

          <p>
            Imajte na umu da kompanije korištene za ciljano oglašavanje i
            reklamiranje imaju svoje politike privatnosti sa kojima bi se
            trebali upoznati.
          </p>

          <h2>Izmjena postavki kolačića</h2>

          <p>
            Ukoliko ne želite da Vaš preglednik zapamti kolačiće ili želite da
            ograničite upotrebu Vaših ličnih podataka na platformi{" "}
            <strong>Rezervacija</strong> ili na web lokacijama trećih strana,
            pogledajte sljedeće preporuke za podešavanja kolačića na mreži.
            Potrebno je obratiti pažnju i na politiku privatnosti treće strane.
          </p>

          <p>
            Skoro svaki preglednik Vam omogućava uklanjanje kolačića i
            prilagođavanje upotrebe drugih tehnologija za praćenje Vašeg
            kretanja na mreži. Ukoliko želite isključiti tehnologije praćenja,
            možete pogledati meni “Pomoć” na vašem pregledniku ili uđite u
            postavke uređaja.{" "}
          </p>

          <p>
            Pored toga, postavke oglašavanja se mogu i podesiti preko postavki
            Vašeg mobilnog uređaja.
          </p>

          <p>
            Ukoliko ne želite da Vaše podatke koristi Google Analytics, potrebno
            je instalirati dodataka za preglednik pod nazovom Google Analytics
            Opt-Out prateći uputstva na sljedećem linku:{" "}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noreferrer"
            >
              https://tools.google.com/dlpage/gaoptout
            </a>
            . Ukoliko želite da Google uopće ne koristi Vaše kolačiće, to možete
            uraditi tako da ćete posjetiti ovu stranicu:{" "}
            <a
              href="https://www.google.com/policies/technologies/ads/"
              traget="_blank"
              rel="noreferrer"
            >
              https://www.google.com/policies/technologies/ads/
            </a>
            .{" "}
          </p>

          <p>
            Pored toga, ukoliko želite veću kontrolu nad upotrebom vaših podatka
            od strane Facebook-a, posjetite sljedeću stranicu za više
            informacija:{" "}
            <a
              href="https://www.facebook.com/about/ads"
              target="_blank"
              rel="noreferrer"
            >
              https://www.facebook.com/about/ads
            </a>
            ,{" "}
            <a
              href="https://www.facebook.com/about/ads"
              target="_blank"
              rel="noreferrer"
            >
              https://www.facebook.com/about/ads
            </a>
            ,{" "}
            <a
              href="https://www.facebook.com/ads/preferences/edit/"
              target="_blank"
              rel="noreferrer"
            >
              https://www.facebook.com/ads/preferences/edit/
            </a>
            .
          </p>

          <p>
            Treba uzeti u obzir i okolnost kada se na mreži pojavljuju neciljani
            oglasi ukoliko se Vaši interesi na Facebook-u poklapaju sa
            interesima publike koja ima neku poveznicu sa našim poslom.{" "}
            <strong>Rezervacija</strong> na može kontrolisati prikazivanje
            ovakvih oglasa iako ste možda prethodno odustali od primanja sličnih
            oglasa, kao što je navedeno u dijelu “Povlačenje saglasnosti i
            odbijanje direktnog marketinga”.{" "}
          </p>

          <p>
            Kako bi saznali više od kolačićima koje koristi treća strana i kako
            ih ukloniti, pozovite se na njihova pravila o privatnosti.{" "}
          </p>

          <p>
            Ukoliko imate bilo kakva dodatna pitanja vezana za privatnost i
            zaštitu podataka, molimo da nam se obratite na email adresu{" "}
            <a href="mailto:podrska@rezervacija.app">podrska@rezervacija.app</a>
            .
          </p>
        </Container>
      </section>
    </>
  )
}

export default Privacy
