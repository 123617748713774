import Sidebar from "../../../components/admin/Sidebar"
import AdminEmailSend from "../AdminEmail/AdminEmailSend"
import AdminPushNotifications from "../AdminPushNotif/AdminPushNotifications"
import AdminSalonList from "../AdminSalon/AdminSalonList"
import AdminUserList from "../AdminUsers"
import AdminUsers from "../AdminUsers/Users"

const sidebarItems = [
  {
    name: "Lista neodoborenih salona",
    component: <AdminSalonList />,
  },
  {
    name: "Lista neodoborenih korisnika",
    component: <AdminUserList />,
  },
  {
    name: "Slanje maila",
    component: <AdminEmailSend />,
  },
  {
    name: "Slanje push notifikacije",
    component: <AdminPushNotifications />,
  },
  {
    name: "Korisnici",
    component: <AdminUsers />,
  },
]

const AdminDashboard = () => {
  return (
    <>
      <Sidebar items={sidebarItems} />
    </>
  )
}

export default AdminDashboard
