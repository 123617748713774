import { Link } from "react-router-dom"

import Container from "../Container"
import Stores from "../Stores"
import styles from "./Footer.module.scss"

import Icon from "../Icon"

function Footer() {
  return (
    <footer className={styles.footer}>
      <Container className="flex flex--space flex--column">
        <div className={styles.QR}>
          <Icon name="qr-code" />
          <p>
            Skenirajte QR kod putem kamere ili aplikacije na Vašem telefonu i
            preuzmite aplikaciju za Vaš uređaj
          </p>
        </div>

        <Stores className={styles.footer__stores} />

        <ul className={styles.footer__links}>
          <li>
            <Link to="/sta-nudimo">Šta nudimo?</Link>
          </li>
          <li>
            <Link to="/o-nama">O nama</Link>
          </li>
          {/* <li>
            <Link to="/novosti">Novosti</Link>
          </li> */}
          <li>
            <Link to="/kontakt">Kontakt</Link>
          </li>
          <li>
            <Link to="/faq">FAQs</Link>
          </li>
          <li>
            <Link to="/partneri">Postani partner</Link>
          </li>
        </ul>

        <ul className={styles.footer__social}>
          <li>
            <a
              href="https://www.facebook.com/rezervacijaapp"
              target="_blank"
              title="Facebook"
              rel="noreferrer"
            >
              <span className="screen-reader">Facebook</span>
              <Icon name="fb" />
            </a>
          </li>
          <li>
            <a
              href="https://www.instagram.com/rezervacija.app/"
              target="_blank"
              title="Instagram"
              rel="noreferrer"
            >
              <span className="screen-reader">Instagram</span>
              <Icon name="ig" />
            </a>
          </li>
          <li>
            <a
              href="https://www.linkedin.com/company/rezervacija"
              target="_blank"
              title="LinkedIn"
              rel="noreferrer"
            >
              <Icon name="ln" />
              <span className="screen-reader">LinkedIn</span>
            </a>
          </li>
        </ul>

        <div className={styles.copyright}>
          <p>© 2022 Rezervacija - All rights Reserved</p>
          <ul className={styles.copyright__links}>
            <li>
              <Link to="/politika-privatnosti">Politika privatnosti</Link>
            </li>
            <li>
              <Link to="/uslovi-koristenja">Uslovi koristenja</Link>
            </li>
          </ul>
        </div>
      </Container>
    </footer>
  )
}

export default Footer
