import { forwardRef } from "react"

import styles from "./InputField.module.scss"

interface IProps {
  type: string
  id: string
  label: string
  placeholder: string
}

const InputField = forwardRef<HTMLInputElement, IProps>(
  ({ type, id, label, placeholder }, ref) => {
    return (
      <div className={styles.input__group}>
        <label htmlFor={id}>{label}</label>
        <input type={type} id={id} placeholder={placeholder} ref={ref} />
      </div>
    )
  }
)

export default InputField
