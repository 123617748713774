import { useState } from "react"
import styles from "./Sidebar.module.scss"
import headerStyles from "../../Header/Header.module.scss"
import { NavToggle } from "../../Header/HeaderLogic"
import Button from "../../Button"
import { useAppDispatch } from "../../../store/hooks"
import { useNavigate } from "react-router-dom"
import { logout } from "../../../store/slices/auth-slice"

interface IProps {
  items: {
    name: string
    component: JSX.Element
  }[]
}

const Sidebar: React.FC<IProps> = ({ items = [] }) => {
  const [currentComponent, setcurrentComponent] = useState(items[0].component)
  const [toggle, toggleNavigation] = NavToggle()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const headerToggle = toggle
    ? `${styles.adminSidebar} ${styles.active}`
    : `${styles.adminSidebar}`

  const logoutHandler = async () => {
    await dispatch(logout())
    navigate("/admin")
  }
  return (
    <>
      <div className={headerToggle}>
        {items.map((item) => (
          <p
            onClick={() => setcurrentComponent(item.component)}
            key={item.name}
          >
            {item.name}
          </p>
        ))}

        <Button
          text="Logout"
          clickHandler={logoutHandler}
          className={styles.logoutBtn}
        />
        <button
          className={headerStyles.hamburger__menu}
          onClick={() => toggleNavigation()}
        >
          <div></div>
          <div></div>
          <div></div>
        </button>
        <nav className={styles.mobile__navigation}>
          <ul>
            {items.map((item) => (
              <li
                onClick={() => setcurrentComponent(item.component)}
                key={item.name}
              >
                {item.name}
              </li>
            ))}
            <Button text="Logout" clickHandler={logoutHandler} />
          </ul>
        </nav>
      </div>
      <div className={styles.sidebarLayout}>{currentComponent}</div>
    </>
  )
}

export default Sidebar
