import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios from "axios"
import authHeader from "../../helpers/auth-header"

export const getUsers = createAsyncThunk(
  "application/unverifiedusers",
  async (_, thunkAPI) => {
    try {
      let response = await axios.get(
        "https://api.rezervacija.app/application/unverifiedusers",
        { headers: authHeader() }
      )

      return { list: response.data }
    } catch (e) {
      return thunkAPI.rejectWithValue("Error getUnApproved while fetching")
    }
  }
)

export interface UserList {
  Id: number
  // Name: string
  Email: string
  //Number: string
}

const initialState = {
  list: [],
  status: "",
}

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUsers.fulfilled, (state, action) => {
      state.list = action.payload.list
      state.status = "success"
    })

    builder.addCase(getUsers.pending, (state, action) => {
      state.status = "loading"
    })

    builder.addCase(getUsers.rejected, (state, action) => {
      state.list = []
      state.status = "failed"
    })
  },
})

export const userActions = usersSlice.actions
export default usersSlice
