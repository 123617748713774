import axios from "axios"
import React, { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import Button from "../../../components/Button"
import Container from "../../../components/Container"
import authHeader from "../../../helpers/auth-header"
import { backendUrl, responseStatus } from "../../../helpers/globalVariables"
import styles from "../AdminEmail/AdminEmail.module.scss"

type Inputs = {
  email: string
  password: string
  confirmPassword: string
  token: string | number | boolean
}

export default function AdminUsers() {
  const { register, handleSubmit, reset } = useForm<Inputs>()
  const [isFormSent, setIsFormSent] = useState(false)

  const onPasswordChangeSubmit: SubmitHandler<Inputs> = async (data) => {
    let headers = authHeader()
    data.token = headers.Authorization
    try {
      var response = await axios.post(
        `${backendUrl}authentication/setpassword`,
        data,
        {
          headers,
        }
      )
      if (response.data.Status === responseStatus[200]) {
        reset()
        setIsFormSent(true)
      } else {
        alert(response.data.Message)
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <>
      <Container className="flex flex--space">
        <div>
          <h4>Korisnici</h4>

          {isFormSent && (
            <p className={styles.formConfirmation}>Izmjena uspjesno uradjena</p>
          )}
        </div>
      </Container>

      <Container className={styles.mailForm}>
        <form onSubmit={handleSubmit(onPasswordChangeSubmit)}>
          <div>
            <p>Za promjenu sifre korisnika, unesite mail i novu sifru </p>
          </div>
          <div>
            <label htmlFor="email">Email usera</label>
            <input
              type="email"
              id="email"
              placeholder="Email usera"
              {...register("email", { required: true })}
            />
          </div>

          <div>
            <label htmlFor="password">Novi password</label>
            <input
              type="text"
              id="password"
              placeholder="Novi password "
              {...register("password", { required: true })}
            />
          </div>

          <div>
            <label htmlFor="confirmPassword">Confirm password</label>
            <input
              type="text"
              id="confirmPassword"
              placeholder="Confirm password "
              {...register("confirmPassword", { required: true })}
            />
          </div>

          <Button
            isSubmit={true}
            text="Promijeni sifru"
            className={styles.btnSend}
          />
        </form>
      </Container>
    </>
  )
}
