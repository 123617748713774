import { FC } from "react"

import styles from "./QuestionBox.module.scss"

interface IProps {
  className?: string
  numPoint: string
  title: string
  body: string
}

const QuestionBox: FC<IProps> = ({ className, numPoint, title, body }) => {
  return (
    <>
      <div className={styles.box}>
        <div className={styles.numPoint}>
          <span>{numPoint}</span>
        </div>
        <div className={styles.content}>
          <h2>{title}</h2>
          <p>{body}</p>
        </div>
      </div>
    </>
  )
}

export default QuestionBox
