import { useEffect, useState } from "react"

export const Scroll = () => {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 150)
    })
  }, [])

  return scroll
}

export const NavToggle = () => {
  const [toggle, setToggle] = useState(false)

  const toggleNavigation = () => {
    setToggle(!toggle)
  }

  return [toggle, toggleNavigation] as const
}
