import Container from "../../components/Container"
import Box from "../../components/Box"
import Icon from "../../components/Icon"

import styles from "./Home.module.scss"

import ApplicationImage from "../../assets/images/application.png"
import Stores from "../../components/Stores"
import PartnerImage from "../../assets/images/partneri-slika.png"
import Button from "../../components/Button"
import Contact from "../../components/Contact"

const Home = () => {
  return (
    <>
      <section className={styles.hero__section}>
        <Container className="flex">
          <div className={styles.hero__content}>
            <h1>
              Recite <span className="green">zbogom </span>
              <span className="break">redovima i čekanju</span>
            </h1>
            <h2>
              Rezervacija omogućava brzo i sigurno rezervisanje svih vrsta
              usluga i termina. Preuzmi aplikaciju sada!
            </h2>

            <Stores className="hide-sm" />
          </div>

          <div className={styles.hero__image}>
            <img src={ApplicationImage} alt="Aplikacija" />
          </div>

          <Stores className={`${styles.hero__stores} hide-lg`} />
        </Container>
      </section>

      <section className={styles.benefits__section}>
        <Container className={`${styles.benefits__grid} flex flex--space`}>
          <Box
            pointNo="01"
            title="Brza registracija"
            description="Registruj se e-mailom ili pristupi profilom društvene mreže"
            icon={<Icon name="notes" />}
          ></Box>

          <Box
            pointNo="02"
            title="Rezervisanje je besplatno"
            description="Bez skrivenih troškova, korištenje Rezervacije je besplatno"
            icon={<Icon name="free" />}
          ></Box>

          <Box
            pointNo="03"
            title="Tvoj grad na dlanu"
            description="Od salona ljepote do zubarskih ordinacija, rezerviši termin bilo gdje i bilo kad"
            icon={<Icon name="city" />}
          ></Box>

          <Box
            pointNo="04"
            title="Bez zloupotreba"
            description="Implementirali smo sisteme zaštite korisnika i partnera"
            icon={<Icon name="secure" />}
          ></Box>
        </Container>
      </section>

      <Container className="padding flex flex--space">
        <div className={styles.partner__image}>
          <img src={PartnerImage} alt="Postanite naš partner" />
          <div className={styles.partner__underbox}>
            <div></div>
          </div>
        </div>

        <div className={styles.partner__content}>
          <h2>
            <span className="break">Najbolji u svom poslu?</span>{" "}
            <span>
              Postani naš <span className="green">partner!</span>
            </span>
          </h2>
          <p>
            Olakšaj si komunikaciju sa klijentima i ostvari sljedeće pogodnosti:
          </p>

          <ul>
            <li>jednostavno upravljanje rezervacijama</li>
            <li>zaštita od zloupotrebe</li>
            <li>lak unos novih usluga</li>
            <li>prioritetna korisnička podrška</li>
            <li>bez skrivenih troškova</li>
            <li>marketinške pogodnosti</li>
          </ul>

          <Button link="/partneri" text="Saznaj više" />
        </div>
      </Container>

      <Contact />
    </>
  )
}

export default Home
