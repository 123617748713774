import axios from "axios"
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../../../components/Button"
import Container from "../../../components/Container"
import InputField from "../../../components/InputField"
import authHeader from "../../../helpers/auth-header"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { getUsers, UserList } from "../../../store/slices/user-slice"

export default function AdminUserList() {
  const usersCtx = useAppSelector((state) => state.users)
  const dispatch = useAppDispatch()
  const [editId, seteditId] = React.useState(0)
  const phoneNumberRef = React.useRef<HTMLInputElement>(null)

  useEffect(() => {
    dispatch(getUsers())
  }, [dispatch])

  const handleSaveChanges = async () => {
    const phoneNumber = phoneNumberRef.current?.value || ""
    try {
      if (phoneNumber && editId) {
        await axios.put(
          "https://api.rezervacija.app/application/verifyacc",
          {
            id: editId,
            number: phoneNumber,
          },
          { headers: authHeader() }
        )

        // navigate("/admin/dashboard")
        seteditId(0)
      }
    } catch (e) {
      alert(e)
    }
  }
  return (
    <>
      <Container className="flex flex--space">
        <div>
          <h4>Lista neodobrenih korisnika</h4>
        </div>
      </Container>

      <Container>
        <table>
          <thead>
            <tr>
              <th>ID Korisnika</th>
              <th>Mail</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {usersCtx.status === "success" &&
              usersCtx.list.map((el: UserList) => {
                return (
                  <React.Fragment key={el.Email}>
                    <tr>
                      <td>{el.Id}</td>
                      <td>{el.Email}</td>
                      <td>
                        <Button
                          text={el.Id === editId ? "Zatvori" : "Uredi"}
                          clickHandler={(e) =>
                            seteditId(el.Id === editId ? 0 : el.Id)
                          }
                        />
                      </td>
                    </tr>
                    {el.Id === editId && (
                      <div style={{ padding: "10px  0" }}>
                        <InputField
                          type="text"
                          placeholder="Unesi broj"
                          label="broj telefona"
                          id="broj telefona"
                          ref={phoneNumberRef}
                        />
                        <Button
                          text="Spremi"
                          clickHandler={handleSaveChanges}
                        />
                      </div>
                    )}
                  </React.Fragment>
                )
              })}
          </tbody>
        </table>
      </Container>
    </>
  )
}
