import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import Button from "../../../components/Button"
import Container from "../../../components/Container"
import { useAppDispatch, useAppSelector } from "../../../store/hooks"
import { logout } from "../../../store/slices/auth-slice"
import { getSalons, SalonList } from "../../../store/slices/salon-slice"

export default function AdminSalonList() {
  const salonCtx = useAppSelector((state) => state.salon)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getSalons())
  }, [dispatch])

  const salonClickHandler = (id: number) => {
    navigate(`/admin/salon/${id}`)
  }
  return (
    <>
      <Container className="flex flex--space">
        <div>
          <h4>Lista neodobrenih salon</h4>
        </div>
      </Container>

      <Container>
        <table>
          <thead>
            <tr>
              <th>ID Salona</th>
              <th>Ime</th>
              <th>Broj</th>
              <th>Mail</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {salonCtx.status === "success" ? (
              salonCtx.list.map((el: SalonList) => {
                return (
                  <tr key={el.SalonId}>
                    <td>{el.SalonId}</td>
                    <td>{el.Name}</td>
                    <td>{el.Number}</td>
                    <td>{el.Mail}</td>
                    <td>
                      <Button
                        text="Vidi vise"
                        clickHandler={(e) => salonClickHandler(el.SalonId)}
                      />
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr></tr>
            )}
          </tbody>
        </table>
      </Container>
    </>
  )
}
