import { FC, useState, useRef } from "react"
import { useForm, SubmitHandler } from "react-hook-form"

import Container from "../Container"
import Icon from "../Icon"
import Button from "../Button"
import styles from "./Contact.module.scss"
import axios from "axios"

interface IProps {
  displayContent?: boolean
}

type Inputs = {
  firstName: string
  lastName: string
  phone: string
  email: string
  companyName: string
  category: string
  message: string
}

const Contact: FC<IProps> = ({ displayContent = true }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Inputs>()

  const sentConfirmation = useRef<null | HTMLParagraphElement>(null)

  const [isSending, setIsSending] = useState(false)
  const [isFormSent, setIsFormSent] = useState(false)

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    setIsSending(true)

    //backend requires message to not be empty string
    if (!data.message) {
      data.message = "empty message"
    }

    try {
      await axios.post("https://api.rezervacija.app/application/contact", data)
      setIsSending(false)
      reset()
      setIsFormSent(true)
      if (sentConfirmation.current) {
        sentConfirmation.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        })
      }
    } catch (e) {
      console.log(e)
      setIsSending(false)
    }
  }

  return (
    <section className={styles.contact__section}>
      <Container className={`${styles.contact__container}`}>
        {displayContent && (
          <div className={styles.contact__content}>
            <Icon name="logo" />
            <h2>Kontaktirajte nas</h2>
            <p>Trebate još informacija ili želite započeti proces prijave?</p>
            <p>Popunite formular i mi ćemo vam se javiti.</p>
          </div>
        )}
        {isFormSent && (
          <p className={styles.formConfirmation} ref={sentConfirmation}>
            Poruka uspješno poslana. Tim Rezervacija će Vas kontaktirati u što
            kraćem roku
          </p>
        )}
        <form
          className={styles.contact__form}
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className={styles.input__row}>
            <div className={styles.input__control}>
              <label htmlFor="name">Ime</label>
              <input
                type="text"
                id="name"
                placeholder="Vaše Ime"
                {...register("firstName", { required: true, minLength: 3 })}
              />
              <p className={styles.errorMsg}>
                {errors.firstName &&
                  "Ime mora sadržavati najmanje 3 karaktera."}
              </p>
            </div>

            <div className={styles.input__control}>
              <label htmlFor="lastName">Prezime</label>
              <input
                type="text"
                id="lastName"
                placeholder="Vaše prezime"
                {...register("lastName", { required: true })}
              />
              <p className={styles.errorMsg}>
                {errors.lastName &&
                  "Prezime mora sadržavati najmanje 3 karaktera."}
              </p>
            </div>
          </div>

          <div className={styles.input__row}>
            <div className={styles.input__control}>
              <label htmlFor="phone">Telefon</label>
              <input
                type="text"
                id="phone"
                placeholder="Vaš telefon u formatu +38XXXXXXXXXX"
                {...register("phone", {
                  required: true,
                  pattern: /^\+?[0-9 ]+$/m,
                })}
              />
              <p className={styles.errorMsg}>
                {errors.phone && "Telefon mora sadržavati samo brojeve"}
              </p>
            </div>

            <div className={styles.input__control}>
              <label htmlFor="email">Email adresa</label>
              <input
                type="email"
                id="email"
                placeholder="Vaša ispravna email adresa"
                {...register("email", {
                  required: true,
                  pattern: /^\S+@\S+\.\S+$/,

                  //pattern: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
                })}
              />
              <p className={styles.errorMsg}>
                {errors.email && "E-mail mora biti validan"}
              </p>
            </div>
          </div>

          <div className={styles.input__row}>
            <div className={styles.input__control}>
              <label htmlFor="companyName">Naziv kompanije</label>
              <input
                type="text"
                id="companyName"
                placeholder="Naziv Vaše kompanije"
                {...register("companyName", { required: false })}
              />
              <p className={styles.errorMsg}></p>
            </div>

            <div className={styles.input__control}>
              <label htmlFor="category">Vrsta djelatnosti</label>
              <select
                id="category"
                {...register("category", { required: true })}
                defaultValue={"N/A"}
              >
                <option value="N/A" disabled>
                  Odaberite djelatnost
                </option>
                <option value="saloni">Saloni ljepote</option>

                <option value="ugostiteljstvo">Ugostiteljstvo</option>

                <option value="smjestaj">Smještaj</option>

                <option value="sport">Sport</option>

                <option value="autoradnje">Automehaničarske radnje</option>

                <option value="vozila">Vozila</option>

                <option value="ordinacije">Ordinacije</option>

                <option value="ostalo">Ostalo</option>
              </select>
              <p className={styles.errorMsg}></p>
            </div>
          </div>

          <div className={styles.input__control}>
            <label htmlFor="message">Poruka</label>
            <textarea
              id="message"
              cols={30}
              rows={10}
              placeholder="Unesite Vašu poruku"
              {...register("message", { required: true, minLength: 10 })}
            ></textarea>

            <p className={styles.errorMsg}>
              {errors.message &&
                "Poruka mora sadržavati najmanje 10 karaktera "}
            </p>
          </div>
          {!isSending ? (
            <Button isSubmit={true} text="Pošalji" className={styles.btnSend} />
          ) : (
            <button className={`${styles.btnSend} ${styles.btnSendDisabled}`}>
              Slanje u toku
              <svg
                version="1.1"
                id="L9"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 100 100"
                enableBackground="new 0 0 0 0"
                xmlSpace="preserve"
              >
                <path
                  fill="#fff"
                  d="M73,50c0-12.7-10.3-23-23-23S27,37.3,27,50 M30.9,50c0-10.5,8.5-19.1,19.1-19.1S69.1,39.5,69.1,50"
                >
                  <animateTransform
                    attributeName="transform"
                    attributeType="XML"
                    type="rotate"
                    dur="1s"
                    from="0 50 50"
                    to="360 50 50"
                    repeatCount="indefinite"
                  />
                </path>
              </svg>
            </button>
          )}
        </form>
      </Container>
    </section>
  )
}

export default Contact
