import styles from "./Box.module.scss"

interface IProps {
  pointNo: string
  title: string
  description: string
  icon: JSX.Element
}

const Box: React.FC<IProps> = ({ pointNo, title, description, icon }) => {
  return (
    <div className={styles.box}>
      {icon}
      <div className={styles.rect}></div>

      <span>{pointNo}</span>
      <h2>{title}</h2>
      <p>{description}</p>
    </div>
  )
}

export default Box
