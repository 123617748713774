import Container from "../../components/Container"
import Icon from "../../components/Icon"

import styles from "./About.module.scss"

import ApplicationImage from "../../assets/images/application.png"
import Stores from "../../components/Stores"

const About = () => {
  return (
    <>
      <section className={styles.hero__section}>
        <Container className="text-center">
          <div className={styles.hero__content}>
            <h1>
              Umorni od čekanja i redova? I mi. Zato smo kreirali{" "}
              <span className="green">Rezervaciju</span>.
            </h1>

            <div className={styles.hero__image}>
              <img src={ApplicationImage} alt="Aplikacija" />
            </div>
          </div>
        </Container>
      </section>

      <section>
        <Container className={styles.page__content}>
          <Icon name="bosnia-flag" />
          <h2>
            Rezervacija je bosanskohercegovačka platforma koja omogućava
            korisnicima pristup različitim djelatnostima, te sigurnu i
            jednostavnu rezervaciju termina i usluga.
          </h2>
        </Container>
      </section>

      <section className={styles.mockup__section}>
        <Container>
          <div className={styles.mockup__image}>
            <img src={ApplicationImage} alt="Aplikacija" />
          </div>
        </Container>
      </section>

      <section>
        <Container className={styles.page__content}>
          <h2>
            Korisnici mogu besplatnu aplikaciju preuzeti na Apple Store i Google
            Play servisima, a poslovni subjekti Rezervaciju mogu koristiti za
            intuitivnije vođenje djelatnosti i pristup proširenoj bazi korisnika
            usluga i kupaca.
          </h2>
          <div className={styles.page__stores}>
            <Stores />
          </div>
        </Container>
      </section>
    </>
  )
}

export default About
