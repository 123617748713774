import { Link } from "react-router-dom"

import Container from "../../components/Container"
import Icon from "../../components/Icon"
import QuestionBox from "../../components/QuestionBox"

import styles from "./FAQ.module.scss"

const FAQ = () => {
  return (
    <>
      <section className={styles.hero__section}>
        <Container className="text-center">
          <h1>
            Imate pitanje o <span className="green">Rezervaciji?</span> Veoma je
            moguće da smo dali odgovor ovdje. Ne možete pronaći odgovor na
            pitanje? <Link to="/kontakt">Pišite nam!</Link>
          </h1>
        </Container>
      </section>

      <Container className={`${styles.arrow} text-center`}>
        <Icon name="arrow-down" />
      </Container>

      <section className={styles.questions}>
        <Container>
          <QuestionBox
            numPoint="01"
            title="Šta je Rezervacija?"
            body=" Rezervacija je bosanskohercegovačka platforma koja omogućava korisnicima pristup različitim djelatnostima, te sigurnu i jednostavnu rezervaciju termina i usluga."
          />

          <QuestionBox
            numPoint="02"
            title="Gdje mogu skinuti aplikaciju?"
            body="Aplikaciju možete preuzeti na Vašem Andorid i Apple uređaju skeniranjem QR koda ili klikom na App/Google Store badge koji se nalazi u zaglavlju."
          />

          <QuestionBox
            numPoint="03"
            title="Da li se plaća korištenje Rezervacije?"
            body="Korištenje Rezervacije se ne plaća za regularne korisnike. Vlasnici djelatnosti su također oslobođeni plaćanja u početnoj fazi, dok će se u budućnosti usluge korištenja za Vlasnike djelatnosti naplaćivati."
          />

          <QuestionBox
            numPoint="04"
            title="Kako mogu izvršiti rezervaciju termina?"
            body="Rezervaciju termina možete veoma jednostavno izvrišiti. Sve što trebate jeste da napravite korisnički račun putem mobilne aplikacije. Klikom na jednu od ponuđenih radnji možete izvršiti pregled usluga, uposlenika i skladno tome rezervisati određeni termin u odabranoj radnji."
          />

          <QuestionBox
            numPoint="05"
            title="Kako mogu promijeniti termin rezervacije?"
            body="Termin rezervacije nije moguće promijeniti na zakazanoj rezervaciji ali postoji mogućnost otkazivanja termina i ponovne rezervacije termina kako biste promijenili vrijeme i datum termina"
          />

          <QuestionBox
            numPoint="06"
            title="Kako mogu otkazati rezervaciju?"
            body="Klikom na Narudžbe ikonu možete vidjeti aktivne i prethodne narudžbe. Klikom na jednu od aktivnih narudžbi dobijete pristup gumbu koji glasi 'Otkaži narudžbu'. Klikom na taj gumb Vi otkazujete narudžbe."
          />

          <QuestionBox
            numPoint="07"
            title="Nisam dobio odgovor na zahtjev za rezervacijom termina!"
            body="Kada rezervišete termin, Vi ste automatski prihvaćeni ukoliko vlasnik ili uposlenik ne odbiju Vaš termin. Ukoliko dođe do otkazivanje termina od strane uposlenog ili vlasnika, Vi dobijete notifikaciju o tome."
          />

          <QuestionBox
            numPoint="08"
            title="Zašto ne mogu poslati zahtjev za rezervaciju?"
            body="Ukoliko imate problema prilikom rezervacije termina molimo da se obratite na info@rezervacija.app ili putem kontakt forme."
          />

          <QuestionBox
            numPoint="09"
            title="Kako prijaviti grešku?"
            body="Za sve greške, sugestije i/ili pitanja stojimo Vam na raspolagaju. Pišite nam na info@rezervacija.app ili nas kontaktiraje koristeći kontakt formu."
          />
        </Container>
      </section>
    </>
  )
}

export default FAQ
