import { FC } from "react"

import styles from "./Benefit.module.scss"

interface IProps {
  numPoint: string
  title: string
  body: string
  reverse?: boolean
}

const Benefit: FC<IProps> = ({ numPoint, title, body, reverse }) => {
  let reverseClass = !reverse ? "" : styles.reverse

  return (
    <>
      <div className={`${styles.box} ${reverseClass} flex flex--space`}>
        <div className={styles.numPoint}>{numPoint}</div>

        <div className={styles.box__content}>
          <h3>{title}</h3>
          <p>{body}</p>
        </div>
      </div>
    </>
  )
}

export default Benefit
