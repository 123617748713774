import Container from "../../components/Container"

import styles from "./Terms.module.scss"

const Terms = () => {
  return (
    <>
      <section className={styles.hero__section}>
        <Container className="text-center">
          <h1>Uslovi korištenja</h1>
          <p>Ažurirano: 2.5.2022</p>
        </Container>
      </section>

      <section className={`${styles.page__content}`}>
        <Container>
          <p>
            Korištenjem <strong>Rezervacije</strong>, slažete se i prihvatate
            određene uslove i pravila korištenja (u daljem tekstu “Uslovi
            korištenja”) koji su navedeni u tekstu ispod.{" "}
          </p>

          <h2>Za šta služi Rezervacija? </h2>

          <p>
            Putem platforme <strong>Rezervacija</strong> možete da sigurno, brzo
            i jednostavno rezervišete termin za usluge koje nude naši partneri
            putem djelatnosti kojim se bave (npr. saloni ljepote, izdavanje
            smještaja, lični treninzi, ugostiteljski objekti, i sl.).{" "}
            <strong>Rezervacija</strong> Vas povezuje sa partnerom koji pruža
            usluge ali ni u kojem slučaju ne garantuje za kvalitet pružene
            usluge, niti za ispravnost i blagovremenost usluge za koju ste
            odabrali napraviti rezervaciju. Rezervacija ne odgovara za bilo koji
            vid štete po bilo kojem osnovu, a koja šteta je nastupila usljed
            direktnih ili indirektnih radnji ili propuštanja naših partnera.
          </p>

          <p>
            Svu odgovornost za način na koji je usluga obavljena, nakon što je
            za istu rezervisan termin putem <strong>Rezervacije</strong>, snosi
            isključivo naš partner kod kojeg ste se odlučili napraviti
            rezervaciju. S obzirom da <strong>Rezervacija</strong> ne može
            uticati na sami kvalitet usluge niti kontrolisati bilo koji dio
            obavljanja usluge, <strong>Rezervacija</strong> ne odgovara za bilo
            kakvu štetu ili gubitke nastale nemarnom ili pogrešnom uslugom ili
            uslugom koja nije u skladu sa Vašim očekivanjima. Za sve reklamacije
            ili nezadovoljstva vezana za kvalitetu obavljene usluge kod našeg
            partnera, molimo da se obratite njima direktno i po mogućnosti odmah
            na licu mjesta. <strong>Rezervacija</strong> Vam omogućava da
            ostavite svoj utisak o našem partneru na dijelu predviđenom na to
            ili da nam se javite na{" "}
            <a href="mailto:podrska@rezervacija.app">podrska@rezervacija.app</a>{" "}
            ukoliko imate dodatne sugestije ili nezadovoljstva vezana za rad
            platforme ili usluge koje pružaju naši partneri.
          </p>

          <h2>Rezervisanje termina</h2>

          <p>
            Kako bi rezervisali termin kod našeg partnera, u obavezi ste da
            putem platforme <strong>Rezervacija</strong> dostavite tačne podatke
            potrebne za rezervisanje termina ili obavljanje usluge koju ste
            odabrali.
          </p>

          <p>
            Ukoliko Vaša rezervacija iz bilo kojeg razloga ne bude prihvaćena od
            strane našeg partnera, proslijedit ćemo Vam njihovo objašnjenje i
            prijedlog za rezervaciju drugog termina.
          </p>

          <p>
            Kada je termin kod našeg partnera rezervisan i potvrđen, u obavezi
            ste da na vrijeme dođete na rezervisani termin i platite rezervisanu
            uslugu. Ukoliko ste iz nekog razloga spriječeni da dođete na
            rezervisani termin, isto tako ste u obavezi da blagovremeno otkažete
            termin tj. minimalno 24 sata prije.
          </p>

          <p>
            Iako <strong>Rezervacija</strong> pruža sve mogućnosti za
            jednostavno rezervisanje termina kod naših partnera, ne garantujemo
            da će rezervisani termin biti ispoštovan od bilo koje strane.
          </p>

          <p>
            <strong>Rezervacija</strong> Vam daje i mogućnost pomijeranja
            rezervisanog termina za neki drugi dan ili u drugo vrijeme, pa Vas s
            toga molimo da nam se javite na{" "}
            <a href="mailto:podrska@rezervacija.app">podrska@rezervacija.app</a>{" "}
            ukoliko imate takav zahtjev.{" "}
          </p>

          <h2>Cijene i uslovi</h2>

          <p>
            Iako su cijene usluga navedene na platformi{" "}
            <strong>Rezervacija</strong> jednake cijenama koje naši partneri
            nude u svojim salonima, ugostiteljskim objektima, ordinacijama, i
            sl., <strong>Rezervacija</strong> ne može garantovati da će cijene
            navedene na platformi uvijek biti ispravne i ažurirane, jer naši
            partneri, tj. vlasnici salona, objekata, i sl. gdje se rezervišu
            usluge su dužni da iste blagovremeno ažuriraju na platformi. U
            svakom slulaju, naši partneri su u obavezi da nas obavijeste o bilo
            kojoj eventualnoj promjeni cijena prije nego što nove cijene iskažu
            na mjestu na kojem se rezerviše termin, kako bi se stvarne cijene
            reflektovale na platformi. <strong>Rezervacija</strong> ne snosi
            nikakvu odgovornost za bilo koju štetu koja može nastati uslijed
            pogrešno prikazanih cijena ili ponude usluga na platformi.
          </p>

          <p>
            Rezervacija termina je za sada potpuno besplatna za obične
            korisnike, dok će naši partneri tj. vlasnici plaćati usluge
            oglašavanja i rezervisanja termina na platformi nekada u budućnosti,
            u zavisnosti od vrste plana koji odaberu. Kada se usluge oglašavanja
            i rezervisanja termina budu naplaćivale, Rezervacija ne snosi
            nikakvu odgovornost za pogrešno unesene podatke i ne vrši spremanje
            detalja plaćanja (ukoliko se plaćanje vrši kreditnom karticom) nego
            to radi putem korištenja trećeg alata kao što je PayPal.{" "}
          </p>

          <p>
            Potrebno je naglasiti da se za pojedine usluge prikazane na
            platformi mogu prikazati samo početne ili okvirne cijene, u
            zavisnosti od vrste usluge. Konačna cijena takvih usluga se utvrđuje
            na mjestu na kojem je rezervisan termin.
          </p>

          <h2>Korisnički sadržaj</h2>

          <p>
            Sav sadržaj postavljen od strane korisnika na platformi{" "}
            <strong>Rezervacija</strong> ostaje u neekskluzivnom vlasništvu
            servisa <strong>Rezervacija</strong> koji ga kao takvog bez ikakvih
            ograničenja može koristiti u svrhu promocije ili prilagođavati
            ukoliko je to potrebno. U ovaj sadržaj spadaju korisnički utisci,
            izjave, komentari i fotografije koji se postavlja u dijelu na
            platformi predviđenim za sugestije, pohvale, kritike i iskustva
            korisnika. Samim postavljanjem bilo kakvog sadržaja, slažete se i
            prihvatate da servis <strong>Rezervacija</strong> isti može objaviti
            kao utisak ili izjavu sa prikazanim vašim imenom.{" "}
          </p>

          <p>
            Ne garantujemo ispravnost sadržaja, lokacije na kojoj se rezervisana
            usluga obavlja, naziva, kao ni bilo kojih drugih podataka unesenih
            od strane naših partnera.
          </p>

          <p>
            Izričito se zabranjuje postavljanje neprikladnog ili uznemirujućeg
            sadržaja, sadržaja koji na bilo koji način krši domaće i međunarodne
            zakone i odredbe, sadržaja koji dovodi ili može dovesti do pogrešnog
            dojma o usluzi na koju se odnosi, sadržaja reklamnog karaktera,
            sadržaja koji sadrži kompjuterske viruse, i bilo kojeg drugog
            sadržaja koji može biti štetan ili predstavlja obilježja klevete
            prema pozitivnim propisima.{" "}
          </p>

          <p>
            Strogo se zabranjuje postavljanje sadržaja koji sadrži klevete,
            pozive na bojkot, te sadržaja koji je predstavljen kao da je
            postavljen od strane <strong>Rezervacije</strong> ili kao da je
            sadržaj nekog drugog korisnika.{" "}
          </p>

          <h2>Zloupotreba servisa</h2>

          <p>
            Strogo je zabranjena svaka zloupotreba platforme{" "}
            <strong>Rezervacija</strong> u smislu nedozvoljenog korištenje
            podataka, sabotiranja drugih korisnika da koriste platformu,
            pokušaja hakiranja i ulaska u dijelove platforme koji su zaštićeni.
          </p>

          <p>
            <strong>Rezervacija</strong> sa punim pravom može da, bez prethodne
            najave i objašnjenja, onemogući pristup korisnicima koji budu kršili
            pravila, pogotovo onima koji se budu lažno predstavljali i kreirali
            lažne rezervacije, rezervisali termine na koje se ne pojave u
            zakazano vrijeme ili na bilo koji drugi način pokušavaju da
            zloupotrijebe platformu. Ukoliko imate potrebu da prijavite
            ponašanje korisnika koje nije u skladu sa propisima i ponašanje koje
            ugrožava rad platforme, molimo Vas da nam se javite na adresu{" "}
            <a href="mailto:podrska@rezervacija.app">podrska@rezervacija.app</a>
            .{" "}
          </p>

          <h2>Poricanje garancija</h2>

          <p>
            S obzirom da je platforma <strong>Rezervacija</strong> razvijena
            isključivo s namjerom da bude informativnog karaktera te da služi
            kao posrednik između korisnika i naših partnera koji nude usluge
            pružajući im bolji pristup informacijama,{" "}
            <strong>Rezervacija</strong> ne pruža nikakve garancije niti snosi
            odgovornost ukoliko dođe do nastanke bilo kakve štete, gubitaka
            nastalih kao posljedica korištenja platforme ili nemogućnosti
            korištenja platforme. Rezervacija ne daje nikakve izražene,
            podrazumijevane ili propisane garancije, što uključuje i sve
            garancije koje daju naši partneri. Pored toga,{" "}
            <strong>Rezervacija</strong> se odriče i garancija za pogodnosti za
            odgovarajuću namjenu, garancija protiv kršenja intelektualnih prava
            korisnika, te ne garantuje da će platforma u svakom momentu raditi
            besprijekorno.
          </p>

          <h2>Ograničenje odgovornosti</h2>

          <p>
            Vi u potpunosti shvatate i prihvatate da, uslijed nepredviđenih
            situacija, može doći do greške u radu platforme, strukturalne greške
            ili drugih problema koji mogu prouzrokovati gubitak podataka, štetu
            ili drugi neočekivani gubitak. <strong>Rezervacija</strong> neće
            snositi odgovornost za bilo kakve štete nastale iz ili koje su u
            vezi sa korištenjem ili mogućnosti koje pruža platforma. Nastale
            štete za koje <strong>Rezervacija</strong> neće snositi odgovornost,
            uključuju, ali nisu ograničene na, posebne, nenamjerne ili
            nenamjerne štete, gubitak profita ili gubitak podataka, bez obzira
            na to što se takve štete mogu predvidjeti. Važno je napomenuti da se
            ograničenje odgovornosti prvenstveno odnosi na štete koje su nastale
            zbog drugih informacija, usluga, servisa, sugestija ili proizvoda
            koji su bili dostupni preko linkova ili reklama na samoj platformi.
          </p>

          <p>
            Vi prihvatate da <strong>Rezervacija</strong> (uključujući vlasnike,
            direktore i uposlenike) ni na koji način ne snose odgovornost za
            bilo koje povrede ili zdravstvene probleme do kojih može doći, kao
            ni za smrt koja ima veze sa uslugom koja je rezervisana preko
            platforme <strong>Rezervacija</strong> ili je uzrokovana netačnim
            informacijama, podacima i greškama partnera koji pruža uslugu.{" "}
          </p>

          <h2>Obavještenje e-poštom i telefonom</h2>

          <p>
            Rezervacija ima potpuno pravo da Vas kontaktira putem e-pošte ili
            telefonom (SMS, glasovne poruke, notifikacije, i sl.) sa svrhom
            obavještavanja o stanju vašeg naloga, vaših rezervacija i
            promotivnih ponuda. Ukoliko niste zainteresovani da primate
            promotivne poruke, Rezervacija Vam pruža mogućnost da se odjavite sa
            liste pretplatnika.
          </p>

          <h2>Autorska prava</h2>

          <p>
            Platforma <strong>Rezervacija</strong> (uključujući mobilnu
            aplikaciju, mikro web stranice i web stranicu) je u potpunosti
            vlasništvo kompanije Leftjoin. Sav sadržaj na platformi kao i
            korišteni softver se mogu koristiti samo u privatne svrhe. Korišteni
            softver na platformi je isključivo razvijen za platformu Rezervacija
            i zaštićen je međunarodnim zakonima koji se odnose na tu oblast.
            Svako korištenje platforme u komercijalne svrhe ili u svrhe za koje
            nije namijenjena je izričito zabranjeno. Ni u kojem slučaju nije
            dozvoljeno koristiti platformu kako bi ostvarili dobit od prodaje
            proizvoda ili usluga ili za unaprijeđenje rejtinga bilo koje druge
            platforme ili sajta u komercijalne svrhe, kao ni preuzimanje
            rezultata pretraživanja platforme Rezervacija, njihovo modifikovanje
            i objavljivanje na drugim platformama ili sajtovima. U slučaju
            postupanja suprotno prednje navedenim uputstvima i dobroj vjeri koja
            se zahtijeva u obligaciono-pravnim odnosima, štetnik odgovara za
            štetu Rezervaciji po svim osnovima, a prema općim pravilima o
            građanskoj odgovornosti. Za sve dalje informacije vezane za
            mogućnost korištenja platforme Rezervacija u komercijalne svrhe,
            molimo da nam se obratite na adresu{" "}
            <a href="mailto:podrska@rezervacija.app">podrska@rezervacija.app</a>
            .
          </p>

          <h2>Zaštićeno ime</h2>

          <p>
            <strong>Rezervacija</strong> je komercijalno ime platforme{" "}
            <strong>Rezervacija</strong> i vlasništvo je kompanije Leftjoin.
            Svako korištenje ovog imena ili logotipa mimo platforme na bilo
            kojoj drugoj platformi, za druge proizvode ili usluge je zabranjeno.
            Pored toga, zabranjeno je i korištenje imena i logotipa koji liče na
            ime i logotip <strong>Rezervacije</strong> do te mjere da mogu
            dovesti do nejasnoća i zabluda korisnika i trećeg lica da se radi o
            platformi, uslugama ili proizvodima koji su povezani sa platformom{" "}
            <strong>Rezervacija</strong>.{" "}
          </p>

          <h2>Promjene Uslova korištenja</h2>

          <p>
            <strong>Rezervacija</strong> zadržava sva prava da bez prethodne
            najave i bez naročitog objašnjenja povremeno ažurira Uslove
            korištenja platforme, kao i da izmijeni cijenovnik ili izgled
            platforme u bilo kojem trenutku, uključujući i pravo da obustavi rad
            platforme, sa ili bez najave, bez da ima ikakvu odgovornost prema
            Vama ili bilo kojem trećem licu. Sve uvedene promjene će se
            automatski pojaviti na platformi <strong>Rezervacija</strong> i
            stupaju na snagu odmah po objavljivanju na platformi. Ukoliko želite
            ostati u toku sa aktuelnim promjenama, preporučuje se da povremeno
            posjetite dio platforme na kojem se nalaze Uslovi korištenja.{" "}
          </p>

          <p>
            Korištenjem platforme <strong>Rezervacija</strong> Vi prihvatate i
            slažete se sa navedenim uslovima korištenja kao i sa svim
            eventualnim promjenama.
          </p>

          <h2>Ostalo</h2>

          <p>
            Ovi Uslovi korištenja služe kao ugovor između platforme{" "}
            <strong>Rezervacija</strong> i Vas, kao korisnika, u kojem je
            navedeno sve potrebno za pravilno korištenje platforme Rezervacija i
            kao takav zamijenjuje sve prethodne dogovore (uključujući usmene i
            pismene), razgovore ili ugovore koji su sklopljeni a nisu ovdje
            spomenuti. Jedini način da bilo kakva odstupanja od odredaba ovog
            ugovora imaju dejstvo jeste da su ista načinjena pismeno od strane{" "}
            <strong>Rezervacije</strong>.{" "}
          </p>

          <p>
            Ukoliko se ne slažete sa bilo kojim od navedenih uslova ili pravila,
            molimo Vas da prestanete koristiti platformu{" "}
            <strong>Rezervacija</strong>.
          </p>
        </Container>
      </section>
    </>
  )
}

export default Terms
