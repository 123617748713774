import Container from "../../components/Container"

import styles from "./NotFound.module.scss"

import Button from "../../components/Button"

const NotFound = () => {
  return (
    <>
      <section className={styles.hero__section}>
        <Container className="text-center">
          <h1>Error 404</h1>
          <h2>Stranica koju ste tražili nije pronađena</h2>
          <br />
          <Button link="/" text="Idi na početnu" />
        </Container>
      </section>
    </>
  )
}

export default NotFound
