import { FC } from "react"

import Icon from "../Icon"
import styles from "./Stores.module.scss"

interface IProps {
  className?: string
}

const Stores: FC<IProps> = (props) => {
  let cNames = props.className
    ? `${styles.mobile__stores} ${props.className}`
    : styles.mobile__stores

  return (
    <ul className={cNames}>
      <li>
        <a
          href="https://apps.apple.com/hr/app/rezervacija/id1616344992?l=hr"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="apple-badge" />
        </a>
      </li>
      <li>
        <a
          href="https://play.google.com/store/apps/details?id=com.rezervacija.rezervacija_app&hl=en&gl=US"
          target="_blank"
          rel="noreferrer"
        >
          <Icon name="google-badge" />
        </a>
      </li>
    </ul>
  )
}

export default Stores
